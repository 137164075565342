body {
  font-family: "Public Sans", sans-serif !important;
}
/* .page {
  width: 100%;
  height: 100%;
}
.page-bottom-layout {
  display: flex;
  padding-top: 70px;
  .page-content {
      width: 100%;
      padding: 15px;
      word-break: break-word;
      background-color: #eceef6;
      .card {
        background: #ffffff;
        box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.0313726);
        border-radius: 4px;
        border: none;
      }
    }
} */
