* {
  /* margin: 0; */
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}

body {
  line-height: 1.6;
  color: #1a1a1a;
  font-size: 1.6rem;
  overflow-x: hidden;
}
a {
  color: #2196f3;
  text-decoration: none;
}

.login-container {
  display: grid;
  grid-template-rows: minmax(min-content, 100vh);
  grid-template-columns: repeat(2, 50vw);
}
.heading-secondary {
  font-size: 3rem;
}
.prim-header {
  align-items: center;
}
.done-div {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.heading-primary {
  font-size: 4rem;
  margin-bottom: 0;
  align-self: center;
  font-family: "Public Sans", sans-serif;
}
.span-blue {
  color: #2196f3;
}
.signup-container,
.signup-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.signup-container {
  width: 100vw;
  height: 100%;
  /* padding: 10rem 10rem; */
  align-items: flex-start;
  background-color: #ffffff;
  justify-content: center;

  grid-column: 1 / 2;
  grid-row: 1;
}
.signup-form {
  max-width: 45rem;
  width: 100%;
  margin-left: 11rem;
}
.text-mute {
  color: #181c32;
  margin-top: 0;
  font-weight: 600;
  font-family: "Public Sans", sans-serif;
  align-self: center;
}

.input-text {
  font-family: "Public Sans", sans-serif;
  font-size: 1.8rem;
  padding: 1rem 6rem 1rem 2rem;
  border: 0.5px solid #b9b9b9;
  border-radius: 6px;
  line-height: initial;
  background: #ffffff;
  width: 100%;
  color: black;
}
/* .input-text:focus {
  outline-color: #4d5fff;
} */

.btn {
  /* padding: 2rem 3rem; */
  border: none;
  background: #4d5fff;
  border-radius: 6px;
  cursor: pointer;
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  color: #ffffff;
  font-family: "Public Sans", sans-serif;
  font-size: inherit;
}
.btn-login {
  align-self: flex-end;
  width: 100%;
  font-family: "Public Sans", sans-serif;
  margin-top: 2rem;
  height: 38px;
  /* box-shadow: 0 5px 5px #00000020; */
}
.btn-login:active {
  box-shadow: none;
}
.btn-login:hover {
  background: #4d5fff;
  color: #fffcfc;
  /* background: #90abce; */
}
.inp {
  position: relative;
}
.label {
  pointer-events: none;
  position: absolute;
  top: 2rem;
  font-family: "Public Sans", sans-serif;
  left: 2rem;
  color: #00000070;
  font-weight: 500;
  font-size: 1.8rem;
  transition: all 0.2s;
  transform-origin: left;
}
/* .input-text:not(:placeholder-shown) + .label,
.input-text:focus + .label {
  top: 0.7rem;
  transform: scale(0.75);
} */
/* .input-text:focus + .label {
  color: #4d5fff;
} */

.input-icon {
  position: absolute;
  top: 0.7rem;
  right: 2rem;
  font-size: 1.8rem;
  color: #5a5a5a;
}
.input-icon-password {
  cursor: pointer;
}

.label-text {
  display: flex;
  font-weight: 700;
  font-family: "Public Sans", sans-serif;
  margin-bottom: 0;
}
.btn-google {
  color: #222;
  background: #fff;
  border: solid 1px #eee;
  padding: 1.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 1px 2px #00000020;
}

.btn-google img {
  width: 3rem;
  margin-right: 1rem;
}

.login-wrapper {
  max-width: 45rem;
  width: 100%;
}
.line-breaker .line {
  width: 50%;
  height: 1px;
  background: #eee;
}
.line-breaker {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccc;

  margin: 3rem 0;
}
.line-breaker span:nth-child(2) {
  margin: 0 2rem;
}
.welcome-container {
  background: #ffffff;
  height: 100%;
  font-family: "Public Sans", sans-serif;
  display: flex;
  overflow: hidden;
  justify-content: space-around;
  align-items: center;
}
.lg {
  font-size: 32px;
  word-wrap: break-word;
  max-width: 30rem;
}
.welcome-container .welcome-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  /* margin-left: 5rem; */
}
.welcome-container h1 {
  text-align: center;
  max-width: 50rem;
  margin: auto;
  color: #4d5fff;
}

.welcome-logo {
  display: flex;
  justify-content: center;
}
.welcome-logo img {
  width: 51px;
  margin-top: 78px;
}
.lg-div {
  text-align: center;
  font-family: "Public Sans", sans-serif;
}
.fp-div {
  text-align: end;
  font-family: "Public Sans", sans-serif;
}
.bg-div {
  text-align: center;
  color: #949494;
  font-size: 14px;
  /* font-family: Roboto; */
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}
.fp-div .span-error {
  display: inline-flex;
  align-items: center;
}
.img-icon {
  position: fixed;
  top: 20px;
  left: 20px;
}
.fp-divr {
  text-align: right;
}
.forgot_password {
  font-size: 1.5rem;
  color: #4d5fff;
  cursor: pointer;
  font-weight: 600;
}
.reset-otp-input {
  display: flex;
  justify-content: center;
}
.otp-div {
  /* padding-left: 12px; */
  padding-right: 12px;
}
.otp-div input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.otp-inputs {
  height: 59px;
  width: 59px;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
  background: linear-gradient(0deg, #ffffff, #ffffff), #fffbfe;
  border: 1px solid #000000;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  text-align: center;
}
.forgot_password:hover {
  color: #0a58ca;
}
@media only screen and (max-width: 1000px) {
  .signup-container {
    /* background-color: #aaa; */
    width: auto;
    padding: auto;
  }
  .signup-form {
    margin-left: 0;
  }
  html {
    font-size: 54.5%;
  }
}
@media only screen and (max-width: 900px) {
  .login-container {
    width: 100vw;
  }
  .signup-container {
    /* background-color: #aaa; */
    width: max-content;
    padding: auto;
  }
  .signup-form {
    margin-left: 0;
  }
  html {
    font-size: 54.5%;
  }
}

@media only screen and (max-width: 600px) {
  .signup-container {
    width: inherit;
    grid-column: 1/3;
    /* grid-row: 1/3; */
    padding: 0 11rem;
  }

  .welcome-container {
    display: none;
    /* background-color: #aaa; */
  }
}
@media only screen and (max-width: 300px) {
  .signup-container {
    width: inherit;
    /* grid-column: 1/3; */
    /* grid-row: 1/3; */
    padding: 0 6rem;
  }

  .welcome-container {
    display: none;
    /* background-color: #aaa; */
  }
  html {
    font-size: 48.5%;
  }
  .signup-form {
    margin-left: 0;
  }

  /* .input-text:not(:placeholder-shown) + .label,
  .input-text:focus + .label {
    top: 0.6rem;
    transform: scale(0.75);
  }
  .label {
    font-size: 1.9rem;
  } */
}
@media only screen and(max-width:1350) {
  .input-text {
    max-width: 10rem;
  }
}
@media screen and (min-width: 600px) and (max-width: 800px) {
  .signup-container {
    grid-column: 1 / 3;
    grid-row: 1/3;
    padding: 0rem 21rem;
    min-width: 100%;
  }
  .signup-form {
    margin-left: 0;
  }
  .welcome-container {
    display: none;
    /* background-color: #aaa; */
    height: 100vh;
  }
}
@media only screen and(max-width:1280px) {
  .signup-form {
    margin-left: 0;
  }
}
@media screen and (min-width: 800px) and (max-width: 1200px) {
  .signup-container {
    grid-column: 1 / 3;
    grid-row: 1/3;
    padding: 0rem 35rem;
  }
  .signup-form {
    margin-left: 0;
  }
  .welcome-container {
    display: none;
    /* background-color: #aaa; */
    height: 100vh;
  }
}
@media only screen and(max-width:1280px) {
  .signup-form {
    margin-left: 0;
  }
}
.keep-logged-in {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.15rem;
  margin-left: 1rem;
  gap: 1rem;
  font-size: 1.4rem;
  color: #82878e;
}

.keep-logged-in label {
  margin-left: 0rem;
  cursor: pointer;
  color: #82878e;
}
#keep-logged-in {
  margin-right: 8px;
}
.image-container {
  width: 36.6em;
  height: auto;
  object-fit: cover;
  /* margin-right: 5em; */
}
.line-container {
  display: flex;
  align-items: center;
  padding: 3em 0;
}

.line {
  flex-grow: 1;
  height: 1px;
  background-color: #efefef; /* Adjust color as needed */
}

.or {
  padding: 0 10px; /* Adjust padding as needed */
  color: #838383; /* Adjust color as needed */
}
.page-no-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  bottom: -8rem;
}
.page-nos {
  margin: 1rem;
  border: 1px solid #000000;
  width: 3rem;
  height: 3rem;
  background-color: #000000;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 12;
}
.page-nos-white {
  z-index: 12;
  margin: 1rem;
  border: 1px solid #000000;
  width: 3rem;
  height: 3rem;
  background-color: #ffffff;
  color: #000000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.line-background {
  position: absolute;
  width: 18rem;
  height: 0.5px;
  background-color: #000000;
}
